import mixpanel from "mixpanel-browser";
import {applyNewBMUIPatches} from "./patches/new-bm-ui";

interface Config {
    infoUrl: string;
    keepaliveUrl: string;
    companionUrl: string;
    mixpanelToken: string;
    sandbox: string;
}

interface CompanionSettings {
    enableBMKeepAlive?: boolean;
    disableAnalytics?: boolean;
}

declare global {
    interface Window {
        __qlabsConfig: Config;
    }
}
export {};

console.log("[QLABS] Companion Script Loaded");

const INTERVAL = 30000;

// Function to fetch data from the URL
async function keepalive(keepaliveUrl: string) {
    try {
        const response = await fetch(keepaliveUrl, {
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            credentials: "include",
        });
        console.log("[QLABS] BM KeepAlive");
    } catch (error) {
        console.error(
            "[QLABS] Error with BM keep alive; are you logged into business manager?",
            error,
        );
    }
}

var keepAliveInterval;

function getCompanionConfig(): CompanionSettings {
    const config = JSON.parse(
        window.localStorage.getItem("qlabs-companion-config") ?? "{}",
    );
    return config;
}

// Set up the interval to fetch data
function setupKeepAliveInterval() {
    const config = getCompanionConfig();
    if (window?.__qlabsConfig?.keepaliveUrl && config.enableBMKeepAlive) {
        keepAliveInterval = setInterval(
            () => keepalive(window.__qlabsConfig.keepaliveUrl),
            INTERVAL,
        );
    } else {
        console.log("[QLABS] BM KeepAlive Disabled");
    }
}

async function setupAnalyticsTracking() {
    const config = getCompanionConfig();

    if (config.disableAnalytics) {
        return;
    }

    var info = {
        username: 'storefront'
    }
    try {
        const infoResp = await fetch(window.__qlabsConfig.infoUrl);
        info = await infoResp.json();
    } catch (error) {
        // not logged into BM
    }


    mixpanel.init(window.__qlabsConfig.mixpanelToken);

    // for a url path like /on/demandware.store/Sites-Site/default/ViewBM-Home
    // the controller with be ViewBM-Home; extract the controller name
    // if the pattern matches /on/demandware.store/Sites-
    const controller = window.location.pathname.match(
        /\/on\/demandware.store\/Sites-[^\/]*\/.+\/([^\/]*)/,
    );
    if (controller) {
        // add controller to body element
        document.body.setAttribute("data-controller", controller[1]);
        mixpanel.register({ controller: controller[1], context: "bm" });
    } else {
        // get from SFRA (should we just make this a config?)
        mixpanel.register({
            context: 'storefront',
            controller:
                document
                    .querySelector("[data-action]")
                    ?.getAttribute("data-action") ?? "unknown",
        });
    }

    if (info.username) {
        mixpanel.identify(info.username);
    }
    mixpanel.register({
        sandbox: window.__qlabsConfig.sandbox,
    });
    mixpanel.track_pageview(
        { source: "companion" },
        { event_name: "B2CE Page View" },
    );
}

/**
 * Apply fixes to BM
 */
async function setupPatches() {
    await applyNewBMUIPatches()
}

setupAnalyticsTracking();
setupKeepAliveInterval();
setupPatches();
